<template>
  <div class="table-padding">
    <div class="filter-sorting-container">
      <k-search-bar-section
        placeholder="Search By Transaction, Order, Status..."
        hide-sort-option
        hide-filter-option
        hide-column-edit-option
        @on-search="updateSearch"
      />
      <multiple-filter
        class="ms-2"
        :filter-fields="filterFields"
        @on-apply-filter="onApplyFilter"
      />
      <k-sorting
        class="ms-auto"
        hide-column-edit-option
        :demo-checkbox-label="`${ showDemoData ? 'Hide':'Show'} Demo Transactions`"
        show-demo-checkbox
        :sort-items="sortFields"
        @update-sort="updateSort"
        @update-sort-direction="updateSortDirection"
        @update-demo-filter="updateDemoFilter"
      />
    </div>
    <div
      v-if="loading"
      class="spinner-body"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="table-responsive"
    >
      <p-table
        class="transaction-table"
        :fields="tableFields"
        :items="tableItems"
      >
        <template #emptyfiltered>
          <p
            class="
              h2
              d-flex
              table-empty-message
              justify-content-center
              align-items-center
              gap-2
            "
          >
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Items Found </span>
          </p>
        </template>
        <template #empty>
          <p
            class="
              h2
              d-flex
              table-empty-message
              justify-content-center
              align-items-center
              gap-2
            "
          >
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Filtered Items </span>
          </p>
        </template>
        <template #head(orderSeqId)>
          <div class="text-nowrap order-min-width">
            Order Id
          </div>
        </template>
        <template #head(invoiceNumber)>
          <div class="text-nowrap">
            Invoice Number
          </div>
        </template>
        <template #cell(invoiceNumber)="data">
          <span @click="tdmEmits(data.item)"> {{ data.item.invoiceNumber }}</span>
        </template>
        <template #cell(dueDate)="data">
          {{ formattedDate(data.item.dueDate) }}
        </template>
        <template #cell(transactionDate)="data">
          {{ formattedDate(data.item.transactionDate) }}
        </template>
        <template #cell(amount)="data">
          <span class="text-nowrap">
            {{ formatCurrency(data.item.amount, data.item.currency) }}
            <span
              v-if="data.item.discountInCredit"
              v-b-tooltip.hover.bottom="`${data.item.discountInCredit} Credit Points were applied to this invoice`"
              class="credit-point-tooltip"
            >
              CP
            </span>
          </span>
        </template>
        <template #cell(amountPaid)="data">
          {{ data.item.amountPaid && formatCurrency(data.item.amountPaid, data.item.currency) || '' }}
        </template>
        <template #cell(amountWithVat)="data">
          {{ data.item.amountWithVat ? formatCurrency(data.item.amountWithVat, data.item.currency) : formatCurrency(data.item.amount, data.item.currency) }}
        </template>
        <template #cell(amountDue)="data">
          {{ formatCurrency(data.item.amountDue, data.item.currency) }}
        </template>
        <template #cell(status)="data">
          <span class="text-capitalize">
            {{ data.item.status || "" }}
          </span>
        </template>
        <template #cell(dueIn)="data">
          <span
            v-if="data.item.dueIn"
            class="due-variants"
            :class="getDueVariant(data.item.dueIn)"
          >
            {{ data.item.dueIn }}
          </span>
        </template>
        <template #cell(paymentMethod)="data">
          {{ PAYMENT_METHODS[data.item.paymentMethod] || "" }}
        </template>
        <!-- By formatting date and currency doesn't affect the original data by addind $ symbol  -->
        <template #head(check)>
          <span />
          <!-- For setting 3 dots menu header empty -->
        </template>
        <template #cell(threeDotIcon)="data">
          <div v-if="isShowThreeDotIcon(data.item)">
            <b-dropdown
              size="lg"
              variant="none"
              toggle-class="text-decoration-none p-0"
              class="font-inter custom-dropdown"
              no-caret
            >
              <template #button-content>
                <div class="position-relative">
                  <kingpin-icon
                    icon="VerticalThreeDotIcon"
                    size="24px"
                    class="cursor-pointer kp-text-color"
                  />
                  <b-icon
                    class="me-1 notify-color notify-icon-position"
                    icon="circle-fill"
                    scale="0.3"
                  />
                </div>
              </template>
              <b-dropdown-item :to="{ name: ROUTES.APPLY_NOTE.CHILDREN.BY_TRANSACTION.name, params: {id: data.item._id} }">
                <b-icon
                  class="me-1 notify-color"
                  icon="circle-fill"
                  scale="0.5"
                />
                <span>Apply a Credit Note</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </p-table>
    </div>
    <k-table-pagination
      :total-items="itemsCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import {
  PTable,
  KTablePagination,
  KingpinIcon,
  KSearchBarSection,
} from '@kingpin-global/kingpin-ui'
import {
  BIcon, BSpinner, VBTooltip, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { FETCH_TRANSACTIONS } from '@/store/modules/transaction-module'
import { apiToastError } from '@/@core/utils/toast'
import {
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_SUB_TITLE,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { formattedDate } from '@/@core/utils/format'
import {
  TRANSACTION_TYPES,
  PAYMENT_METHODS,
  ROUTES,
} from '@/constants'
import { constants, utils } from '@kingpin-global/kingpin-utils-frontend'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { getTransactionFields } from '@/table-fields-constants'
import HeaderColumns from '../filter/HeaderColumns.vue'
import EditTransaction from './EditTransaction.vue'

const {
  ROLES, TRANSACTION_DUE_DATES, TRANSACTION_STATUS, TRANSACTION_TYPE,
} = constants
const { formatCurrency } = utils

export default {
  name: 'TransactionList',
  components: {
    PTable,
    KTablePagination,
    BIcon,
    BSpinner,
    BDropdown,
    BDropdownItem,
    KingpinIcon,
    KSearchBarSection,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [KSearchAndSortMixin],
  props: {
    trasactionType: {
      type: String,
      default: TRANSACTION_TYPES.RECEIVABLE,
    },
  },
  data() {
    return {
      tableItems: [],
      tableFields: [],
      filteredItems: [],
      itemsCount: 0,
      sortBy: '',
      searchText: '',
      currentPage: 1,
      perPage: 10,
      componentContent: {
        headerColumns: [this.transactionFields],
      },
      loading: true,
      currentPageCount: 0,
      isDesc: false,
      formattedDate,
      formatCurrency,
      editTxnContent: {},
      editTxnSidebar: false,
      editTxnId: 'Transaction ID: ',
      PAYMENT_METHODS,
      editColActive: false,
      ROUTES,
      showDemoData: false,
    }
  },
  computed: {
    transactionFields() {
      return getTransactionFields()
    },
    filterIncludedFields() {
      const extraFields = [
        'brandName',
        'brandEmail',
        'retailerName',
        'retailerEmail',
        'shipmentId',
        'orderId',
        'shipmentSeqId',
      ]
      return this.tableFields.map(fields => fields.key).concat(extraFields)
    },
    sortFields() {
      return this.transactionFields.filter(field => !['invoiceNumber', 'userId', 'amountWithVat', 'amountDue', 'dueIn'].includes(field.key))
    },
  },
  created() {
    this.$root.$refs.TransactionList = this
    this.initPageContent()
  },
  emits: ['update-total-items'],
  methods: {
    async initPageContent() {
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
        isDemo: this.showDemoData,
        role: this.trasactionType === TRANSACTION_TYPES.RECEIVABLE ? ROLES.RETAILER : ROLES.BRAND,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      if (Object.values(this.filterQuery).length) {
        queryParams.filter = JSON.stringify(this.filterQuery)
      }
      await this.$store
        .dispatch(FETCH_TRANSACTIONS, queryParams)
        .then(res => {
          const resData = res.data.data
          this.setTableData(resData.data, resData.count)
          this.getTableFields(this.transactionFields)
          this.setFilterFields(resData.filter, this.transactionFields)
          this.loading = false
        })
        .catch(err => {
          apiToastError(err)
          this.loading = false
        })
    },
    async setTableData(items, count) {
      this.tableItems = items
      this.componentContent.headerColumns = this.transactionFields
      this.itemsCount = count
      this.getCurrentPageCount()
    },
    getTableFields(fields) {
      this.tableFields = fields.filter(field => field.value)
    },
    updateSort(value) {
      this.sortBy = value
    },
    async updateCurrentPage(value) {
      this.currentPage = value
      await this.initPageContent()
    },
    async updatePerPage(value) {
      this.perPage = value
      await this.initPageContent()
    },
    async updateSortDirection(value) {
      this.isDesc = value
      await this.initPageContent()
    },
    onSetEditColumns() {
      this.$store.commit(SET_TITLE, 'Edit Columns')
      this.$store.commit(SET_COMPONENT, HeaderColumns)
      this.$store.commit(SET_COMPONENT_CONTENT, this.componentContent)
      this.$store.commit(SET_FORM_ACTIONS, this.editColformActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.editColActive = !this.editColActive
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    editColformActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.getTableFields(this.componentContent.headerColumns)
          this.toggleSidebar()
          break
        case 'cancel':
          this.toggleSidebar()
          break
        default:
      }
    },
    editTxnFormActions(actionType) {
      switch (actionType) {
        case 'submit':
          /*  TODO:  */
          // do code here ....
          // After edit finishes sumbit button emit
          break
        case 'cancel':
          // The code for cancel is for edit transaction emit its not yet implemented
          this.toggleSidebar()
          break
        default:
          this.toggleSidebar()
      }
    },
    tdmEmits(data) {
      this.editTxnId = `Transaction ID: ${data._id}`
      this.editTxnContent = {
        txnModel: {
          transactionId: data._id,
          orderId: data.orderSeqId || data.orderId,
          dueDate: data.dueDate,
          amountDue: data.amount,
          amountPaid: data.amountPaid,
          status: data.status,
          paymentMethod: data.paymentMethod,
          reference: data.reference,
        },
      }
      this.onSetTransactionEdit()
    },
    onSetTransactionEdit() {
      this.$store.commit(SET_TITLE, 'Edit Transaction')
      this.$store.commit(SET_SUB_TITLE, this.editTxnId)
      this.$store.commit(SET_COMPONENT, EditTransaction)
      this.$store.commit(SET_FORM_ACTIONS, this.editTxnFormActions)
      this.$store.commit(SET_COMPONENT_CONTENT, this.editTxnContent)
      this.toggleSidebar()
    },
    getCurrentPageCount() {
      const totalCount = this.itemsCount
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      } else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
      this.$emit('update-total-items', this.currentPageCount, this.itemsCount)
    },
    getDueVariant(due) {
      return Object.entries(TRANSACTION_DUE_DATES).find(
        ([, value]) => value.TEXT === due,
      )[1].COLOR || 'none'
    },
    isShowThreeDotIcon(item) {
      return item.type === TRANSACTION_TYPE.SHIPMENT && item.status !== TRANSACTION_STATUS.PAID && item?.applicableCreditNoteCount > 0
    },
  },
}
</script>
