<template>
  <validation-observer
    ref="createTxnForm"
  >
    <b-form
      class="edit-txn-form"
      @submit.prevent="onUpdateTransaction"
    >
      <div class="kp-notify inter-500-14">
        <bell-icon /> NOTIFY THE PAYER
      </div>
      <div class="form-fields p-2">
        <k-form-group
          label-content="Transaction ID"
        >
          <k-form-input
            v-model="txnModel.transactionId"
            type="text"
            name="transaction-id"
            placeholder="Transaction ID"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="mt-2"
          label-content="Order ID"
        >
          <k-form-input
            v-model="txnModel.orderId"
            type="text"
            name="order-id"
            placeholder="Order ID"
            disabled
          />
        </k-form-group>
        <k-form-group
          class="mt-2"
          label-content="Due Date"
        >
          <k-form-input
            v-model="dueDate"
            type="date"
            name="due-date"
            placeholder="Due Date"
          />
        </k-form-group>
        <k-form-group
          class="mt-2"
          label-content="Amount Due"
        >
          <k-form-input
            :value="txnModel.amountDue"
            type="text"
            name="amount-due"
            placeholder="Amount Due"
            disabled
          />
        </k-form-group>
        <validation-provider
          #default="{ errors }"
          name="Amount Paid"
          vid="amountPaid"
          rules="required|double"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              Amount Paid <span class="text-danger">*</span>
            </template>
            <k-form-input
              v-model="txnModel.amountPaid"
              type="text"
              name="amount-paid"
              placeholder="Amount Paid"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
          </k-form-group>
        </validation-provider>
        <validation-provider
          v-if="canDisplayAdjustAmount"
          #default="{ errors }"
          name="Adjust Amount"
          vid="adjustAmount"
          rules="double"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
            label-content="Adjust Amount"
          >
            <k-form-input
              v-model="adjustAmount"
              type="number"
              name="adjust-amount"
              placeholder="Adjust Amount"
              :state="errors.length > 0 ? false : null"
              autocomplete="off"
            />
          </k-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Status"
          vid="status"
          rules="required"
        >
          <k-form-group
            class="mt-2"
            label-for="status"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              Status <span class="text-danger">*</span>
            </template>

            <b-dropdown
              class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
              variant="none"
              toggle-class="drop-caret-icon"
              :text="txnModel.status || 'Select a status'"
            >
              <b-dropdown-item
                v-for="option in Object.keys(TRANSACTION_STATUS)"
                :key="option"
                class="text-capitalize"
                :active="txnModel.status === TRANSACTION_STATUS[option]"
                @click="txnModel.status = TRANSACTION_STATUS[option]"
              >
                {{ TRANSACTION_STATUS[option] }}
              </b-dropdown-item>
            </b-dropdown>
          </k-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Payment Method"
          vid="paymentMethod"
          rules="required"
        >
          <k-form-group
            class="mt-2"
            label-for="payment-method"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              Payment Method <span class="text-danger">*</span>
            </template>
            <b-dropdown
              class="w-100 mt-1 k-search-dropdown dropdown-custom-class"
              variant="none"
              toggle-class="drop-caret-icon"
              :text="PAYMENT_METHODS[txnModel.paymentMethod] || 'Select a Payment method'"
            >
              <b-dropdown-item
                v-for="option in Object.keys(PAYMENT_METHODS)"
                :key="option"
                :active="txnModel.paymentMethod === option"
                @click="txnModel.paymentMethod = option"
              >
                {{ PAYMENT_METHODS[option] }}
              </b-dropdown-item>
            </b-dropdown>
          </k-form-group>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="Reference"
          vid="reference"
          rules="required|min:8"
        >
          <k-form-group
            class="mt-2"
            label-for="reference"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              Reference <span class="text-danger">*</span>
            </template>
            <b-form-textarea
              v-model="txnModel.reference"
              name="Reference"
              placeholder="Add a Reference"
              :state="errors.length > 0 ? false : null"
            />
          </k-form-group>
        </validation-provider>
      </div>
      <div class="d-flex mt-3 flex-row">
        <k-button
          variant="info"
          type="submit"
          block
          :disabled="loading"
        >
          UPDATE
          <loader-icon v-if="loading" />
        </k-button>
        <k-button
          class="ms-4"
          variant="outline-info"
          block
          :disabled="loading"
          @click="$emit('dynamic-emits', 'cancel')"
        >
          CANCEL
        </k-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import { formatDateForInput, formattedDate } from '@/@core/utils/format'
import { apiToastErrorV2, apiToastSuccessV2 } from '@/@core/utils/toast'
import { PAYMENT_METHODS, TRANSACTION_STATUS } from '@/constants'
import '@fontsource/inter'
import '@fontsource/work-sans'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import {
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BellIcon, LoaderIcon } from 'vue-feather-icons'

export default {
  name: 'EditTransaction',
  components: {
    BForm,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BellIcon,
    LoaderIcon,
    KButton,
    KFormGroup,
    KFormInput,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      txnModel: this.componentContent.txnModel,
      TRANSACTION_STATUS,
      PAYMENT_METHODS,
      formattedDate,
      adjustAmount: 0,
      canDisplayAdjustAmount: false,
      dueDate: null,
    }
  },
  computed: {
    isTransactionUpdated() {
      return this.txnModel?.paymentMethod || this.txnModel?.reference || this.txnModel.amountPaid > 0
    },

  },
  created() {
    this.canDisplayAdjustAmount = this.txnModel.amountPaid > 0
    if (this.txnModel?.dueDate) {
      this.dueDate = formattedDate(this.txnModel.dueDate)
      this.dueDate = formatDateForInput(this.dueDate)
    }
  },
  emits: ['dynamic-emits'],
  methods: {
    async onUpdateTransaction() {
      this.loading = true
      const transactionId = this.txnModel.transactionId
      const payload = this.createPayload()
      try {
        const res = await this.$kpRequest({
          ...this.$kpParams(this.$kpEndpoint.transactions.patch, transactionId),
          payload,
        })
        apiToastSuccessV2(res.data.message)
        this.$root.$refs.TransactionList.initPageContent()
        this.$emit('dynamic-emits', 'cancel')
      } catch (err) {
        apiToastErrorV2(err)
      } finally {
        this.loading = false
      }
    },
    createPayload() {
      const payload = {
        dueDate: new Date(this.dueDate),
      }
      if (this.isTransactionUpdated) {
        payload.status = this.txnModel.status
        payload.amountPaid = parseFloat(this.txnModel.amountPaid) || 0
        payload.paymentMethod = this.txnModel.paymentMethod || ''
        payload.reference = this.txnModel.reference || ''
      }
      if (this.adjustAmount > 0) {
        payload.adjustAmount = parseFloat(this.adjustAmount)
      }
      return payload
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables-components.scss";
@import "../../assets/scss/kingpin-style.scss";
.edit-txn-form {
  padding: 0% $side-bar-form-padding;
}

.form-fields {
  overflow-y: scroll;
  height: 70vh;
  @include custom-scroll-bar();
}
</style>
